import styles from '@/components/template/header/HeaderLogo/HeaderLogo.module.scss'
import ThemeImage from '@/components/elements/media/ThemeImage/ThemeImage'
import React from 'react'

const HeaderLogo = () => {
  return (
    <>
      <ThemeImage src={`images/mandatory/logo.png`} height={58} width={244} alt={'logo'} lazyload={false} dpr={true} className={styles.root} />
    </>
  )
}

export default HeaderLogo
