import styles from '@/components/template/header/Header.module.scss'
import React, { useState, Fragment, useCallback, useEffect } from 'react'
import MainNavigation from 'components/template/navigations/MainNavigation'
import { Container, Navbar } from 'react-bootstrap'
import Link from 'next/link'
import tenantCmsObject from '@/entities/tenantCms/interface'
import HeaderLogo from '@/components/template/header/HeaderLogo'

// eslint-disable-next-line react/display-name
const MemoMainNavigation = React.memo((Props) => {
  // @ts-ignore
  return <MainNavigation {...Props} />
})
// eslint-disable-next-line react/display-name
const MemoHeaderLog = React.memo(() => {
  // @ts-ignore
  return <HeaderLogo />
})

interface Props {
  cms: tenantCmsObject
}

const Header = ({ cms }: Props) => {
  // navbar color
  const colorTriggerDistance = 100
  const [colorChange, setColorChange] = useState(false)
  const changeNavbarColor = () => {
    if (window.scrollY >= colorTriggerDistance) {
      setColorChange(true)
    } else {
      setColorChange(false)
    }
  }
  if (typeof window !== 'undefined') {
    window.addEventListener('scroll', changeNavbarColor, { passive: true })
  }

  // navbar visibility
  const visibilityTriggerDistance = 500
  const initialY = typeof window !== 'undefined' ? window.scrollY : 0
  const [visible, setVisible] = useState(true)
  const [y, setY] = useState(initialY)

  const handleNavbarVisibility = useCallback(
    (e: Event) => {
      const window: Window = e.currentTarget as unknown as Window
      if (y > window.scrollY) {
        setVisible(true)
      } else if (y < window.scrollY) {
        if (window.scrollY > visibilityTriggerDistance) {
          setVisible(false)
        }
      }
      setY(window.scrollY)
    },
    [y]
  )

  useEffect(() => {
    setY(window.scrollY)
    window.addEventListener('scroll', handleNavbarVisibility, { passive: true })

    return () => {
      window.removeEventListener('scroll', handleNavbarVisibility)
    }
  }, [handleNavbarVisibility])

  return (
    <>
      <Fragment>
        <Navbar
          collapseOnSelect
          expand="xl"
          fixed="top"
          className={`${styles.navbar} ${colorChange ? styles.bgWhite : ''} ${!visible ? styles.hidden : ''}`}
        >
          <Container fluid>
            <Link href={'/'} passHref prefetch={false}>
              <Navbar.Brand data-tr-click={'header:logo:click'}>
                <MemoHeaderLog />
              </Navbar.Brand>
            </Link>
            {/*@ts-ignore*/}
            <MemoMainNavigation categories={cms.categories} contactBlockSettings={cms.config.contactBlockSettings} />
          </Container>
        </Navbar>
      </Fragment>
    </>
  )
}

export default Header
