import styles from './ContactModal.module.scss'
import React, { useState } from 'react'
import Icon from '@/components/elements/Icon'
import cmsContactBlockSettingsObject from '@/entities/cmsContactBlockSettings/interface'
import { Button, Col, Modal, Row } from 'react-bootstrap'
import { useTranslation } from 'next-i18next'
import ThemeImage from '@/components/elements/media/ThemeImage/ThemeImage'

interface Props {
  contactBlockSettings: cmsContactBlockSettingsObject
}

const ContactModal = ({ contactBlockSettings }: Props) => {
  const { t } = useTranslation('common')
  const [show, setShow] = useState(false)

  const { slogan, text, phone, openingHours1, openingHours2, email } = contactBlockSettings
  const phoneLink = phone ? phone.replace('(0)', '').split(' ').join('') : ''

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  return (
    <>
      <button className="btn-unstyled icon-link" aria-label="Kontakt" onClick={handleShow} data-tr-click={'header:contact_modal:open'}>
        <Icon name="phone-call" className="icon" />
      </button>
      <Modal show={show} onHide={handleClose} className={styles.modal}>
        <Modal.Header closeButton className={styles.header}>
          <Modal.Title className={styles.title}>{slogan}</Modal.Title>
        </Modal.Header>
        <Modal.Body className={styles.body}>
          <Row>
            <Col className={'mb-4'}>{text}</Col>
          </Row>
          <Row className={'justify-content-center'}>
            <Col xs={'auto'}>
              <Row className={styles.contactBlock}>
                <Col xs={'auto'} className={styles.icon}>
                  <ThemeImage src={'/images/mandatory/icons/contact/phone.png'} alt="icon" width={60} height={60} />
                </Col>
                <Col className={'d-flex align-items-center'}>
                  <div>
                    <div className={styles.link}>
                      <a href={'tel:' + phoneLink} data-tr-click="header:contact_modal_phone:click">
                        {phone}
                      </a>
                    </div>
                    <div className={styles.openingHours}>
                      {openingHours1}
                      {openingHours2 ? (
                        <>
                          <br />
                          {openingHours2}
                        </>
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                </Col>
              </Row>
              <Row className={`${styles.contactBlock} mb-0`}>
                <Col xs={'auto'} className={styles.icon}>
                  <ThemeImage src={'images/mandatory/icons/contact/mail.png'} alt="icon" width={60} height={60} />
                </Col>
                <Col className={'d-flex align-items-center'}>
                  <div className={styles.link}>
                    <a href={'mailto:' + email} data-tr-click="header:contact_modal_email:click">
                      {email}
                    </a>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer className={styles.footer}>
          <Button variant="light" onClick={handleClose} className={styles.button}>
            {t('button.close')}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}
export default ContactModal
